import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useGetSessionIdQuery, usePostShareDataMutation} from '../network/apiService';
import './SecureForm.css';

const SecureForm: React.FC = () => {
    const [inputData, setSensitiveInfo] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [postShareData] = usePostShareDataMutation();
    const {data} = useGetSessionIdQuery({});
    const [securing, setSecuring] = useState(false);

    const handleUsernameChange = (value: string) => {
        if (value === '') {
            setUsername('');
            setPassword(''); // Clear password when username is cleared
        } else if (password === '') {
            setPassword(' '); // Temporarily set password to prevent mismatch
        }
        setUsername(value);
    };

    const handlePasswordChange = (value: string) => {
        if (value === '') {
            setPassword('');
            setUsername(''); // Clear username when password is cleared
        } else if (username === '') {
            setUsername(' '); // Temporarily set username to prevent mismatch
        }
        setPassword(value);
    };

    const handleSubmit = () => {
        setSecuring(true);
        const sessionId = data?.data.toString();

        // Validation: both username and password must be filled or both empty
        if ((username.trim() && !password.trim()) || (!username.trim() && password.trim())) {
            toast.error('Both username and password must be filled or both must be empty.');
            setSecuring(false);
            return;
        }

        if (sessionId && inputData.trim()) {
            postShareData({username, password, sessionId, inputData})
                .unwrap()
                .then((result) => {
                    if (result.data !== 'invalid') {
                        goToShareCard(result.data.link);
                        setSecuring(false);
                    } else {
                        toast.error('Invalid Session Id');
                        setSecuring(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.message || 'An error occurred');
                    setSecuring(false);
                });
        } else {
            toast.error('Missing Fields');
            setSecuring(false);
        }
    };

    const navigate = useNavigate();
    const goToShareCard = (data: string) => {
        navigate('/ShareCard', {state: {data}, replace: true});
    };

    return (
        <div className="container">
            <div className="form" aria-autocomplete="none">
                <div className="lock-icon">🔒</div>
                <h2 className={data ? "activated" : "pending-activation"}>
                    {data ? "Activated" : "Pending Activation"}
                </h2>
                <div className="form-group">
                    <label htmlFor="sensitiveInfo">Sensitive Info</label>
                    <textarea
                        id="sensitiveInfo"
                        value={inputData}
                        onChange={(e) => setSensitiveInfo(e.target.value)}
                        placeholder="Enter your sensitive information here"
                        rows={3}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="a1">Username</label>
                    <input
                        type="text"
                        id="a1"
                        name={`a1-${Math.random().toString(36).substr(2, 9)}`}
                        autoComplete="cc-given-name"
                        placeholder="Enter your username here"
                        value={username.trim() === '' ? '' : username} // Prevent showing placeholder if auto-cleared
                        onChange={(e) => handleUsernameChange(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        name={`p1-${Math.random().toString(36).substr(2, 9)}`}
                        placeholder="Enter your password here"
                        autoComplete="off"
                        id="password"
                        value={password.trim() === '' ? '' : password} // Prevent showing placeholder if auto-cleared
                        onChange={(e) => handlePasswordChange(e.target.value)}
                    />
                </div>
                <p className="info-text">
                    Note: Username and password are optional, but if one is filled, the other must also be filled. You
                    can leave both fields empty.
                </p>
                <p style={{color: 'red'}}>
                    Upon access or after 24 hours, your data will be automatically deleted.
                </p>
                <button type="submit" onClick={handleSubmit}>
                    {securing ? "Securing..." : "Secure And Share"}
                </button>
            </div>
        </div>
    );
};

export default SecureForm;
