import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useCheckDataMutation, usePostGetDataMutation} from '../network/apiService';
import './SecureForm.css';

const SecureGetForm: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasPass, setHasPass] = useState(false);
    const [dataRevealed, setDataRevealed] = useState('');
    const [postGetData] = usePostGetDataMutation();
    const [checkSession] = useCheckDataMutation();
    const [access, setQueryString] = useState<string | null>(null);
    const [revealing, setRevealing] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);

    const navigate = useNavigate();

    const goToDataReveal = (data: string) => {
        navigate('/DataReveal', {state: {data}, replace: true});
    };

    const goToEncrypt = () => {
        navigate('/', {replace: true});
    };

    useEffect(() => {
        // Extract the query string from the current URL
        const queryString = window.location.href.split('?')[1];
        setQueryString(queryString || null);
    }, []);

    const checkSessionFunction = async () => {
        setIsLoading(true);
        try {
            const result = await checkSession({access}).unwrap();
            setHasPass(result.data.hasPass);

            if (!result.data.hasPass) {
                // Directly fetch data if no password is required
                await fetchDataDirectly();
            }
        } catch {
            // If session is invalid, show "Expired Secret" message
            setSessionExpired(true);
            toast.error('Session has expired or is invalid.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDataDirectly = async () => {
        try {
            setRevealing(true);
            const result = await postGetData({username: '', password: '', access}).unwrap();
            setDataRevealed(result.data);
            goToDataReveal(result.data);
        } catch {
            toast.error('Failed to retrieve data.');
        } finally {
            setRevealing(false);
        }
    };

    useEffect(() => {
        if (access) {
            checkSessionFunction();
        }
    }, [access]);

    const handleSubmit = async () => {
        if (access) {
            setRevealing(true);
            try {
                const result = await postGetData({username, password, access}).unwrap();
                setDataRevealed(result.data);
                goToDataReveal(result.data);
            } catch {
                toast.error('Invalid Credentials');
            } finally {
                setRevealing(false);
            }
        } else {
            toast.error('Access key is missing or invalid.');
        }
    };

    return (
        <div className="container">
            {/* Full-screen loader */}
            {isLoading && (
                <div className="full-screen-loader">
                    <div className="loader"></div>
                </div>
            )}

            {!isLoading && !sessionExpired && (
                <div className="form">
                    {!revealing ? <div className="lock-icon">🔒</div> : null}
                    <h2>Data Will Be Deleted Once Revealed</h2>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            placeholder="Enter your username here"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            disabled={!hasPass}
                        />
                    </div>
                    {hasPass && (
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                placeholder="Enter your password here"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    )}
                    <p className="info-text">
                        Note: If the data is protected with a username and password, please fill them. Otherwise, leave
                        both
                        fields blank!
                    </p>
                    <p>Upon access or after 24 hours, your data will be automatically deleted.</p>
                    {hasPass && (
                        <button type="submit" onClick={handleSubmit}>
                            {revealing ? 'Revealing...' : 'Reveal'}
                        </button>
                    )}
                </div>
            )}

            {!isLoading && sessionExpired && (
                <div className="form">
                    <div className="lock-icon">🔒</div>
                    <h2 style={{color: 'red'}}>Expired Secret</h2>
                    <button
                        style={{
                            marginTop: '20px',
                            padding: '10px 20px',
                            fontSize: '16px',
                            color: '#fff',
                            backgroundColor: 'green',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                        onClick={goToEncrypt}
                    >
                        Go to Encrypt
                    </button>
                </div>
            )}
        </div>
    );
};

export default SecureGetForm;
