import CryptoJS from 'crypto-js';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './SecureForm.css';
import {toast} from 'react-toastify';

const DataRevealForm: React.FC = () => {
    const location = useLocation();
    const [decrypted, setDecrypted] = useState(false);
    const [dataRevealed, setDataRevealed] = useState('');
    const [pureData, setPureData] = useState('');
    const data = (location.state as { data: string })?.data;
    const [revealing, setRevealing] = useState(false);
    const navigate = useNavigate();
    const {REACT_APP_IV, REACT_APP_KEY} = process.env;

    useEffect(() => {
        setDataRevealed(data);
    }, [data]);

    const goToEncrypt = () => {
        navigate('/', {state: {data}, replace: true});
    };

    const handleDecrypt = async () => {
        try {
            setRevealing(true);

            const iv = CryptoJS.enc.Hex.parse(REACT_APP_IV ?? '');
            const key = CryptoJS.enc.Hex.parse(REACT_APP_KEY ?? '');

            const encryptedBytes = CryptoJS.enc.Hex.parse(dataRevealed);

            const decryptedBytes = CryptoJS.AES.decrypt(
                {ciphertext: encryptedBytes} as any,
                key,
                {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                }
            );

            // Convert decrypted data to a UTF-8 string
            const decryptedTextUtf8 = decryptedBytes.toString(CryptoJS.enc.Utf8);
            if (!decryptedTextUtf8) throw new Error('Decryption failed');

            const parsedData = JSON.parse(decryptedTextUtf8).original[0].value;
            setPureData(parsedData);
            setDecrypted(true);
        } catch (e) {
            toast.error('Decryption failed.');
            console.error(e);
        } finally {
            setRevealing(false);
        }
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(pureData);
            toast.success('Copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <div className="container">
            <div className="form">
                <div className="lock-icon">🔒</div>
                <h2>Data Will Be Deleted Once Revealed</h2>
                {!decrypted ? (
                    <input
                        type="password"
                        placeholder="****************"
                        id="password"
                        value="************************************************"
                        disabled={true}
                    />
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <textarea
                            className="revealed-text"
                            value={pureData}
                            readOnly
                            rows={10}
                            style={{
                                width: '90%', // Take up most of the container width
                                maxWidth: '1200px', // Maximum width for large screens
                                minHeight: '300px', // Increase height for readability
                                fontSize: '1rem',
                                lineHeight: '1.5',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                padding: '10px',
                                backgroundColor: '#f9f9f9',
                                color: '#333',
                                resize: 'both', // Allow both horizontal and vertical resizing
                                overflowX: 'auto', // Horizontal scrolling for long lines
                            }}
                        />
                    </div>
                )}
                <p>Upon access or after 24 hours, your data will be automatically deleted.</p>
                <button type="submit" onClick={decrypted ? copyToClipboard : handleDecrypt}>
                    {decrypted ? 'Copy' : revealing ? 'Decrypting...' : 'Decrypt'}
                </button>
                <p>
                    <a style={{color: 'green'}} href="#" onClick={goToEncrypt}>
                        Go to Encrypt
                    </a>
                </p>
            </div>
        </div>
    );
};

export default DataRevealForm;
